<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';

const route = inject('route');

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    message: '',
    accepted: false,
});

const hasBeenSuccessful = ref(false);

const sendForm = () => {
    form.post(route('form.contact'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => (hasBeenSuccessful.value = true),
    });
};
</script>

<template>
    <div class="">
        <template v-if="hasBeenSuccessful">
            <h2 class="text-2xl font-bold uppercase">
                {{ $t('Thank you for your message!') }}
            </h2>
            <div class="text-4">
                {{ $t('We will be in touch shortly') }}
            </div>
        </template>
        <template v-else>
            <h2 class="hidden mb-4 text-2xl font-bold uppercase md:block">{{ $t('Send us a message') }}</h2>
            <FormKit type="form" id="signup-form" @submit="sendForm" :actions="false">
                <FormKit
                    type="text"
                    name="first_name"
                    :label="$t('First Name')"
                    v-model="form.first_name"
                    :errors="form.errors.first_name"
                    :value="form.first_name"
                    validate="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="text"
                    name="last_name"
                    :label="$t('Last Name')"
                    v-model="form.last_name"
                    :errors="form.errors.last_name"
                    :value="form.last_name"
                    validate="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="email"
                    name="email"
                    :label="$t('E-mail')"
                    validation="required|email"
                    v-model="form.email"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="textarea"
                    name="message"
                    :label="$t('Message')"
                    validation="required"
                    v-model="form.message"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    v-model="form.accepted"
                    type="checkbox"
                    :label="
                        $t('I agree with the Terms and conditions and Privacy policy of Flexable.')
                    "
                    name="terms"
                    validation="accepted"
                    validation-visibility="dirty"
                />
                <Button
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Send')"
                    class="w-auto mt-6"
                />
            </FormKit>
        </template>
    </div>
</template>
