<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { onMounted, ref } from 'vue';
import ContactColumn from './ContactColumn.vue';

const props = defineProps({
    description: String,
});

const flexableSupportMail = ref('');
const flexablePhoneNumber = ref('');
const flexableCompanyName = ref('');
const flexableStreetAddress = ref('');
const flexableZipCity = ref('');

onMounted(async () => {
    flexableSupportMail.value = usePage().props.flexable_support_email;
    flexablePhoneNumber.value = usePage().props.flexable_phone_number;
    flexableCompanyName.value = usePage().props.flexable_company_name;
    flexableStreetAddress.value = usePage().props.flexable_street_address;
    flexableZipCity.value = usePage().props.flexable_zip_city;
});
</script>

<template>
    <div class="text-base">
        <p v-if="description">{{ description }}</p>

        <ContactColumn :title="$t('Call')" v-if="flexablePhoneNumber">
            <Link :href="'tel:' + flexablePhoneNumber" class="block transition-colors duration-300 hover:text-pink">
                {{ flexablePhoneNumber }}
            </Link>
        </ContactColumn>

        <ContactColumn :title="$t('E-mail')" v-if="flexableSupportMail">
            <Link class="block transition-colors duration-300 hover:text-pink" :href="'mailto:' + flexableSupportMail">
                {{ flexableSupportMail }}
            </Link>
        </ContactColumn>

        <ContactColumn :title="$t('Address')" v-if="flexableCompanyName">
            <p>{{ flexableCompanyName }}</p>
            <p>{{ flexableStreetAddress }}</p>
            <p>{{ flexableZipCity }}</p>
        </ContactColumn>
    </div>
</template>
