<script setup>
import { inject } from 'vue';

// Components
import PageHeading from '@/Components/Page/PageHeading.vue';
import ContentGrid from '@/Components/ContentGrid.vue';
import ContactForm from '@/Components/Contact/ContactForm.vue';
import ContactSidebar from '@/Components/Contact/ContactSidebar.vue';
import FaqGlobal from '@/Components/Faq/FaqGlobal.vue';
import ButtonLink from '@/Components/Button/Link.vue';

// Layout
import PublicLayout from '@/Layouts/PublicLayout.vue';

// Partials
import Footer from './Partials/Footer.vue';

const route = inject('route');

const props = defineProps({
    general_faqs: Object,
});
</script>

<template>
    <PublicLayout title="FLEXABLE | Contact">
        <ContentGrid>
            <h1 class="text-[36px] md:text-[60px] font-bold uppercase mb-12">{{ $t('Contact') }}</h1>

            <div class="gap-12 md:flex">
                <div class="md:w-1/4">
                    <ContactSidebar />
                </div>
                <div class="mt-10 md:w-3/4 md:mt-0">
                    <ContactForm />
                </div>
            </div>

            <FaqGlobal :title="$t('Maybe your answer is here')" :generalFaqs="general_faqs" v-if="general_faqs.length">
                <div class="flex flex-col items-center gap-4 mt-10 md:items-start md:flex-row">
                    <ButtonLink
                        :href="route('faqs', { 'currentCategories[]': 'employer' })"
                        class="justify-center min-w-[250px]"
                    >
                        <div class="gap-4 flex-center">
                            <div class="text-base leading-none">
                                {{ $t('FAQ For employers') }}
                            </div>
                        </div>
                    </ButtonLink>

                    <ButtonLink
                        :href="route('faqs', { 'currentCategories[]': 'flexer' })"
                        pink
                        class="justify-center min-w-[250px]"
                    >
                        <div class="gap-4 flex-center">
                            <div class="text-base leading-none">
                                {{ $t('FAQ For Flexers') }}
                            </div>
                        </div>
                    </ButtonLink>
                </div>
            </FaqGlobal>
        </ContentGrid>
    </PublicLayout>
</template>
